import React from "react";
import './VideoSeries.css'


export default function VideoSeries({ children }) {
    return (
        <section className="container video-series">
            {children}
        </section>

    )


}
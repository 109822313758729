
import "./SpiffyCheckout.css"


export default function SpiffyCheckout({ children }) {

    return (
        <div className="spiffyCheckout-box">
            {children}
        </div>


    )
}



export const COHORT_DATE_RANGE_OCTOBER = [
  {
    start: `Nov 5`,
    end: `Dec 3`,
  }
]

export const HARDCORE_DETAILS_OCTOBER = [
  {
    weekNum: '1',

    topicTitle1: 'Align Your Teams to Great UX with UX Outcomes',
    topicDetails1_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails1_2: `You’ll deliver great UX when your products and services exceed your users’ expectations and anticipate your customers’ needs. You’ll need the right metrics to know you’re on the right path. Those metrics come from the ultimate UX outcome: improving the lives of your customers, users, and anyone affected by your work.`,
    topicDetails1_3: `Discover how to create effective UX Outcomes so that…`,
    topicDetails1_bullet1: `Your team and stakeholders are clearly on the same page about how delivering great UX improves people’s lives.`,
    topicDetails1_bullet2: `You’ll move beyond the unhelpful practice of measuring outputs by focusing the team on outcomes.`,
    topicDetails1_bullet3: `You can show how the features of the next release are critical to your long-term UX vision.`,

    topicTitle1_date1: 'Tuesday, Nov 5, 11am - Noon ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Nov 5, 1 - 2pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Reach Your Objectives with Your UX Success Metric',
    topicDetails2_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails2_2: `Everyone needs to be focused on the same goals. You’ll define your OKR objectives at the precise moment your designs will improve your users' and customers’ lives. There’s nothing like clarity to keep everybody on the same page.`,
    topicDetails2_3: `Explore how to create powerful UX Success Metrics so that…`,
    topicDetails2_bullet1: `Your entire team and your stakeholders know exactly what goals they need to aim for.`,
    topicDetails2_bullet2: `You know exactly when to celebrate your success.`,
    topicDetails2_bullet3: `Your senior management clearly sees how your work has improved users’ lives.`,

    topicTitle2_date1: 'Thursday, Nov 7, 11am - Noon ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Thursday, Nov 7, 1 - 2pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
  {
    weekNum: '2',

    topicTitle1: 'Achieve Key Results with Your UX Progress Metrics',
    topicDetails1_1: `High-value, audacious goals take time. We need to show progress. `,
    topicDetails1_2: `Progress happens incrementally and, all too often, invisibly. Identify OKR key results that show your team's work while simultaneously promoting the improvements and benefits your designs are accumulating. Build up your team’s value as your users receive better-designed experiences.`,
    topicDetails1_3: `Discover how to create insightful UX Progress Metrics so that…`,
    topicDetails1_bullet1: `Your development and product teams are eager to prioritize the UX.`,
    topicDetails1_bullet2: `You know exactly what to report in your OKRs.`,
    topicDetails1_bullet3: `Your stakeholders immediately connect the UX improvement to key `,

    topicTitle1_date1: 'Tuesday, Nov 12, 11am - Noon ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Nov 12, 1 - 2 pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Eliminate the Costs of Poor UX with Problem-Value Metrics',
    topicDetails2_1: `Grow executive support for high-value UX efforts. `,
    topicDetails2_2: `Poor UX causes problems for your users and customers. And it often comes back to haunt your organization’s bottom line through the costs of support, training, and lost sales. Now, you can accurately show how much these costs continue to accumulate.`,
    topicDetails2_3: `Explore how to create influential Problem-Value Metrics so that…`,
    topicDetails2_bullet1: `You motivate senior management to take UX seriously because better UX is a win for everyone.`,
    topicDetails2_bullet2: `You win over the finance department as your UX efforts shrink corporate costs.`,
    topicDetails2_bullet3: `You have vital stakeholders praising how better UX improved their department’s fiscal performance.`,

    topicTitle2_date1: 'Thursday, Nov 14, 11am - Noon ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Thursday, Nov 14, 1 - 2pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },

  {
    weekNum: '3',

    topicTitle1: 'Raise UX’s Visibility with Outcome-driven UX Metrics',
    topicDetails1_1: `Make UX metrics a core component of your organization’s culture. `,
    topicDetails1_2: `Tie your UX outcomes directly to the product roadmap. Show how every new capability and feature is on the path to becoming the product everyone wants to own and the service everyone wants to use. Influence the vital strategic decisions that impact user experiences.`,
    topicDetails1_3: `Discover how to create strategic UX metrics so that…`,
    topicDetails1_bullet1: `You reduce the risk of delivering unwanted features and poorly thought-through capabilities.`,
    topicDetails1_bullet2: `You get called into the high-level discussions and meetings that define where your products and services are heading.`,
    topicDetails1_bullet3: `Your UX work becomes proactive, and your understanding of your users becomes invaluable.`,

    topicTitle1_date1: 'Tuesday, Nov 19, 11am - Noon ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Nov 19, 1 - 2 pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Identifying Your UX Outcomes',
    topicDetails2_1: `Bring all the essential players together to identify critical UX Outcomes`,
    topicDetails2_2: `Your Outcome-driven UX Metrics become far more powerful when you’ve gotten buy-in from the vital stakeholders and champions. You’ll discover how to conduct a special UX outcome workshop to pinpoint the improvements in the lives of your users and customers.`,
    topicDetails2_3: `Explore the best practices for crafting UX Outcomes so that`,
    topicDetails2_bullet1: `You orchestrate the buy-in of what great UX looks like.`,
    topicDetails2_bullet2: `You establish a solid “definition of done” that your Agile teams can use to know what to deliver.`,
    topicDetails2_bullet3: `You prevent team members and stakeholders from jumping to solutions before everyone understands the problems.`,

    topicTitle2_date1: 'Thursday, Nov 21, 11am - Noon ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Thursday, Nov 21, Noon - 1pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },


  {
    weekNum: '4',

    topicTitle1: 'Expanding Your UX Research',
    topicDetails1_1: `Use UX metrics as a wedge to sell better UX research.`,
    topicDetails1_2: `You’ll likely need to up your UX research game to deliver high-powered UX metrics. You can only know what to improve if you know what’s currently in need of improvement. Demonstrate the power of strategic UX research.`,
    topicDetails1_3: `Uncover how thorough UX research improves success so that…`,
    topicDetails1_bullet1: `You increase your organization’s UX maturity by building expertise in your users and their needs.`,
    topicDetails1_bullet2: `You ensure every item in your product roadmap solves a necessary problem that customers would be happy to pay for.`,
    topicDetails1_bullet3: `You discover innovative ways to anticipate your customers’ needs and exceed your users’ expectations.`,

    topicTitle1_date1: 'Tuesday, Nov 26, 11am - Noon ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Nov 26, 1 - 2 pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Crafting Your UX Vision',
    topicDetails2_1: `Make your organization an innovative market leader`,
    topicDetails2_2: `A UX vision describes what your experiences will be like in the distant future. You’ll inspire your organization to deliver exceptional products and services for years by crafting a clear vision. You instantly merge future OKR objectives into a human-centered goal with broad support.`,
    topicDetails2_3: `Discover how a compelling UX vision drives your organization so that…`,
    topicDetails2_bullet1: `You influence the highest levels of your organization’s strategy.`,
    topicDetails2_bullet2: `You make your organization the envy of your entire industry, with every other organization holding you up as their goal.`,
    topicDetails2_bullet3: `You drive your organization to see UX as the central competitive promise for everything they do.`,

    topicTitle2_date1: 'Thursday, Dec 3, 11am - Noon ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Thursday, Dec 3, 1 - 2pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
]

export const COHORT_DATE_RANGE_NOVEMBER = [
  {
    start: `Nov 18`,
    end: `Dec 19`,
  }
]

export const HARDCORE_DETAILS_NOVEMBER = [
  {
    weekNum: '1',

    topicTitle1: 'Align Your Teams to Great UX with UX Outcomes',
    topicDetails1_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails1_2: `You’ll deliver great UX when your products and services exceed your users’ expectations and anticipate your customers’ needs. You’ll need the right metrics to know you’re on the right path. Those metrics come from the ultimate UX outcome: improving the lives of your customers, users, and anyone affected by your work.`,
    topicDetails1_3: `Discover how to create effective UX Outcomes so that…`,
    topicDetails1_bullet1: `Your team and stakeholders are clearly on the same page about how delivering great UX improves people’s lives.`,
    topicDetails1_bullet2: `You’ll move beyond the unhelpful practice of measuring outputs by focusing the team on outcomes.`,
    topicDetails1_bullet3: `You can show how the features of the next release are critical to your long-term UX vision.`,

    topicTitle1_date1: 'Monday, Nov 18, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Nov 18, 4 - 5pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Reach Your Objectives with Your UX Success Metric',
    topicDetails2_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails2_2: `Everyone needs to be focused on the same goals. You’ll define your OKR objectives at the precise moment your designs will improve your users' and customers’ lives. There’s nothing like clarity to keep everybody on the same page.`,
    topicDetails2_3: `Explore how to create powerful UX Success Metrics so that…`,
    topicDetails2_bullet1: `Your entire team and your stakeholders know exactly what goals they need to aim for.`,
    topicDetails2_bullet2: `You know exactly when to celebrate your success.`,
    topicDetails2_bullet3: `Your senior management clearly sees how your work has improved users’ lives.`,

    topicTitle2_date1: 'Wednesday, Nov 20, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Nov 20, 4 - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
  {
    weekNum: '2',

    topicTitle1: 'Achieve Key Results with Your UX Progress Metrics',
    topicDetails1_1: `High-value, audacious goals take time. We need to show progress. `,
    topicDetails1_2: `Progress happens incrementally and, all too often, invisibly. Identify OKR key results that show your team's work while simultaneously promoting the improvements and benefits your designs are accumulating. Build up your team’s value as your users receive better-designed experiences.`,
    topicDetails1_3: `Discover how to create insightful UX Progress Metrics so that…`,
    topicDetails1_bullet1: `Your development and product teams are eager to prioritize the UX.`,
    topicDetails1_bullet2: `You know exactly what to report in your OKRs.`,
    topicDetails1_bullet3: `Your stakeholders immediately connect the UX improvement to key `,

    topicTitle1_date1: 'Monday, Dec 2, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Dec 2, 4pm - 5pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Eliminate the Costs of Poor UX with Problem-Value Metrics',
    topicDetails2_1: `Grow executive support for high-value UX efforts. `,
    topicDetails2_2: `Poor UX causes problems for your users and customers. And it often comes back to haunt your organization’s bottom line through the costs of support, training, and lost sales. Now, you can accurately show how much these costs continue to accumulate.`,
    topicDetails2_3: `Explore how to create influential Problem-Value Metrics so that…`,
    topicDetails2_bullet1: `You motivate senior management to take UX seriously because better UX is a win for everyone.`,
    topicDetails2_bullet2: `You win over the finance department as your UX efforts shrink corporate costs.`,
    topicDetails2_bullet3: `You have vital stakeholders praising how better UX improved their department’s fiscal performance.`,

    topicTitle2_date1: 'Wednesday, Dec 4, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Dec 4, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
  {
    weekNum: '3',

    topicTitle1: 'Raise UX’s Visibility with Outcome-driven UX Metrics',
    topicDetails1_1: `Make UX metrics a core component of your organization’s culture. `,
    topicDetails1_2: `Tie your UX outcomes directly to the product roadmap. Show how every new capability and feature is on the path to becoming the product everyone wants to own and the service everyone wants to use. Influence the vital strategic decisions that impact user experiences.`,
    topicDetails1_3: `Discover how to create strategic UX metrics so that…`,
    topicDetails1_bullet1: `You reduce the risk of delivering unwanted features and poorly thought-through capabilities.`,
    topicDetails1_bullet2: `You get called into the high-level discussions and meetings that define where your products and services are heading.`,
    topicDetails1_bullet3: `Your UX work becomes proactive, and your understanding of your users becomes invaluable.`,

    topicTitle1_date1: 'Monday, Dec 9, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Dec 9, 4pm - 5pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Identifying Your UX Outcomes',
    topicDetails2_1: `Bring all the essential players together to identify critical UX Outcomes`,
    topicDetails2_2: `Your Outcome-driven UX Metrics become far more powerful when you’ve gotten buy-in from the vital stakeholders and champions. You’ll discover how to conduct a special UX outcome workshop to pinpoint the improvements in the lives of your users and customers.`,
    topicDetails2_3: `Explore the best practices for crafting UX Outcomes so that`,
    topicDetails2_bullet1: `You orchestrate the buy-in of what great UX looks like.`,
    topicDetails2_bullet2: `You establish a solid “definition of done” that your Agile teams can use to know what to deliver.`,
    topicDetails2_bullet3: `You prevent team members and stakeholders from jumping to solutions before everyone understands the problems.`,

    topicTitle2_date1: 'Wednesday, Dec 11, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Dec 11, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
  {
    weekNum: '4',

    topicTitle1: 'Expanding Your UX Research',
    topicDetails1_1: `Use UX metrics as a wedge to sell better UX research.`,
    topicDetails1_2: `You’ll likely need to up your UX research game to deliver high-powered UX metrics. You can only know what to improve if you know what’s currently in need of improvement. Demonstrate the power of strategic UX research.`,
    topicDetails1_3: `Uncover how thorough UX research improves success so that…`,
    topicDetails1_bullet1: `You increase your organization’s UX maturity by building expertise in your users and their needs.`,
    topicDetails1_bullet2: `You ensure every item in your product roadmap solves a necessary problem that customers would be happy to pay for.`,
    topicDetails1_bullet3: `You discover innovative ways to anticipate your customers’ needs and exceed your users’ expectations.`,

    topicTitle1_date1: 'Monday, Dec 16, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Dec 16, 4pm - 5pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Crafting Your UX Vision',
    topicDetails2_1: `Make your organization an innovative market leader`,
    topicDetails2_2: `A UX vision describes what your experiences will be like in the distant future. You’ll inspire your organization to deliver exceptional products and services for years by crafting a clear vision. You instantly merge future OKR objectives into a human-centered goal with broad support.`,
    topicDetails2_3: `Discover how a compelling UX vision drives your organization so that…`,
    topicDetails2_bullet1: `You influence the highest levels of your organization’s strategy.`,
    topicDetails2_bullet2: `You make your organization the envy of your entire industry, with every other organization holding you up as their goal.`,
    topicDetails2_bullet3: `You drive your organization to see UX as the central competitive promise for everything they do.`,

    topicTitle2_date1: 'Wednesday, Dec 18, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Dec 18, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
]




export const COHORT_DATE_RANGE_DECEMBER = [
  {
    start: `Dec 10`,
    end: `Jan 17`,
  }
]

export const HARDCORE_DETAILS_DECEMBER = [
  {
    weekNum: '1',

    topicTitle1: 'Align Your Teams to Great UX with UX Outcomes',
    topicDetails1_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails1_2: `You’ll deliver great UX when your products and services exceed your users’ expectations and anticipate your customers’ needs. You’ll need the right metrics to know you’re on the right path. Those metrics come from the ultimate UX outcome: improving the lives of your customers, users, and anyone affected by your work.`,
    topicDetails1_3: `Discover how to create effective UX Outcomes so that…`,
    topicDetails1_bullet1: `Your team and stakeholders are clearly on the same page about how delivering great UX improves people’s lives.`,
    topicDetails1_bullet2: `You’ll move beyond the unhelpful practice of measuring outputs by focusing the team on outcomes.`,
    topicDetails1_bullet3: `You can show how the features of the next release are critical to your long-term UX vision.`,

    topicTitle1_date1: 'Tuesday, Dec 10, 10am - 11am ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Dec 10, 12pm - 1pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Reach Your Objectives with Your UX Success Metric',
    topicDetails2_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails2_2: `Everyone needs to be focused on the same goals. You’ll define your OKR objectives at the precise moment your designs will improve your users' and customers’ lives. There’s nothing like clarity to keep everybody on the same page.`,
    topicDetails2_3: `Explore how to create powerful UX Success Metrics so that…`,
    topicDetails2_bullet1: `Your entire team and your stakeholders know exactly what goals they need to aim for.`,
    topicDetails2_bullet2: `You know exactly when to celebrate your success.`,
    topicDetails2_bullet3: `Your senior management clearly sees how your work has improved users’ lives.`,

    topicTitle2_date1: 'Friday, Dec 13, 10am - 11am ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Friday, Dec 13, 12pm - 1pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
  {
    weekNum: '2',

    topicTitle1: 'Achieve Key Results with Your UX Progress Metrics',
    topicDetails1_1: `High-value, audacious goals take time. We need to show progress. `,
    topicDetails1_2: `Progress happens incrementally and, all too often, invisibly. Identify OKR key results that show your team's work while simultaneously promoting the improvements and benefits your designs are accumulating. Build up your team’s value as your users receive better-designed experiences.`,
    topicDetails1_3: `Discover how to create insightful UX Progress Metrics so that…`,
    topicDetails1_bullet1: `Your development and product teams are eager to prioritize the UX.`,
    topicDetails1_bullet2: `You know exactly what to report in your OKRs.`,
    topicDetails1_bullet3: `Your stakeholders immediately connect the UX improvement to key `,

    topicTitle1_date1: 'Tuesday, Dec 17, 10am - 11am ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Dec 17, 12pm - 1pm pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Eliminate the Costs of Poor UX with Problem-Value Metrics',
    topicDetails2_1: `Grow executive support for high-value UX efforts. `,
    topicDetails2_2: `Poor UX causes problems for your users and customers. And it often comes back to haunt your organization’s bottom line through the costs of support, training, and lost sales. Now, you can accurately show how much these costs continue to accumulate.`,
    topicDetails2_3: `Explore how to create influential Problem-Value Metrics so that…`,
    topicDetails2_bullet1: `You motivate senior management to take UX seriously because better UX is a win for everyone.`,
    topicDetails2_bullet2: `You win over the finance department as your UX efforts shrink corporate costs.`,
    topicDetails2_bullet3: `You have vital stakeholders praising how better UX improved their department’s fiscal performance.`,

    topicTitle2_date1: 'Friday, Dec 20, 10am - 11am ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Friday, Dec 20, 12pm - 1pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },

  {
    weekNum: '3',

    topicTitle1: 'Raise UX’s Visibility with Outcome-driven UX Metrics',
    topicDetails1_1: `Make UX metrics a core component of your organization’s culture. `,
    topicDetails1_2: `Tie your UX outcomes directly to the product roadmap. Show how every new capability and feature is on the path to becoming the product everyone wants to own and the service everyone wants to use. Influence the vital strategic decisions that impact user experiences.`,
    topicDetails1_3: `Discover how to create strategic UX metrics so that…`,
    topicDetails1_bullet1: `You reduce the risk of delivering unwanted features and poorly thought-through capabilities.`,
    topicDetails1_bullet2: `You get called into the high-level discussions and meetings that define where your products and services are heading.`,
    topicDetails1_bullet3: `Your UX work becomes proactive, and your understanding of your users becomes invaluable.`,

    topicTitle1_date1: 'Tuesday, Jan 7, 10am - 11am ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Jan 7, 12pm - 1pm pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Identifying Your UX Outcomes',
    topicDetails2_1: `Bring all the essential players together to identify critical UX Outcomes`,
    topicDetails2_2: `Your Outcome-driven UX Metrics become far more powerful when you’ve gotten buy-in from the vital stakeholders and champions. You’ll discover how to conduct a special UX outcome workshop to pinpoint the improvements in the lives of your users and customers.`,
    topicDetails2_3: `Explore the best practices for crafting UX Outcomes so that`,
    topicDetails2_bullet1: `You orchestrate the buy-in of what great UX looks like.`,
    topicDetails2_bullet2: `You establish a solid “definition of done” that your Agile teams can use to know what to deliver.`,
    topicDetails2_bullet3: `You prevent team members and stakeholders from jumping to solutions before everyone understands the problems.`,

    topicTitle2_date1: 'Friday, Jan 10, 10am - 11am ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Friday, Jan 10, 12pm - 1pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },


  {
    weekNum: '4',

    topicTitle1: 'Expanding Your UX Research',
    topicDetails1_1: `Use UX metrics as a wedge to sell better UX research.`,
    topicDetails1_2: `You’ll likely need to up your UX research game to deliver high-powered UX metrics. You can only know what to improve if you know what’s currently in need of improvement. Demonstrate the power of strategic UX research.`,
    topicDetails1_3: `Uncover how thorough UX research improves success so that…`,
    topicDetails1_bullet1: `You increase your organization’s UX maturity by building expertise in your users and their needs.`,
    topicDetails1_bullet2: `You ensure every item in your product roadmap solves a necessary problem that customers would be happy to pay for.`,
    topicDetails1_bullet3: `You discover innovative ways to anticipate your customers’ needs and exceed your users’ expectations.`,

    topicTitle1_date1: 'Tuesday, Jan 14, 10am - 11am ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Tuesday, Jan 14, 12pm - 1pm pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Crafting Your UX Vision',
    topicDetails2_1: `Make your organization an innovative market leader`,
    topicDetails2_2: `A UX vision describes what your experiences will be like in the distant future. You’ll inspire your organization to deliver exceptional products and services for years by crafting a clear vision. You instantly merge future OKR objectives into a human-centered goal with broad support.`,
    topicDetails2_3: `Discover how a compelling UX vision drives your organization so that…`,
    topicDetails2_bullet1: `You influence the highest levels of your organization’s strategy.`,
    topicDetails2_bullet2: `You make your organization the envy of your entire industry, with every other organization holding you up as their goal.`,
    topicDetails2_bullet3: `You drive your organization to see UX as the central competitive promise for everything they do.`,

    topicTitle2_date1: 'Friday, Jan 17, 10am - 11am ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Friday, Jan 17, 12pm - 1pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
]


export const COHORT_DATE_RANGE_JANUARY = [
  {
    start: `Jan 13`,
    end: `Feb 5`,
  }
]

export const HARDCORE_DETAILS_JANUARY = [
  {
    weekNum: '1',

    topicTitle1: 'Align Your Teams to Great UX with UX Outcomes',
    topicDetails1_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails1_2: `You’ll deliver great UX when your products and services exceed your users’ expectations and anticipate your customers’ needs. You’ll need the right metrics to know you’re on the right path. Those metrics come from the ultimate UX outcome: improving the lives of your customers, users, and anyone affected by your work.`,
    topicDetails1_3: `Discover how to create effective UX Outcomes so that…`,
    topicDetails1_bullet1: `Your team and stakeholders are clearly on the same page about how delivering great UX improves people’s lives.`,
    topicDetails1_bullet2: `You’ll move beyond the unhelpful practice of measuring outputs by focusing the team on outcomes.`,
    topicDetails1_bullet3: `You can show how the features of the next release are critical to your long-term UX vision.`,

    topicTitle1_date1: 'Monday, Jan 13, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Jan 13, 4pm - 5pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Reach Your Objectives with Your UX Success Metric',
    topicDetails2_1: `You can’t measure success if you don’t know what your goal is.`,
    topicDetails2_2: `Everyone needs to be focused on the same goals. You’ll define your OKR objectives at the precise moment your designs will improve your users' and customers’ lives. There’s nothing like clarity to keep everybody on the same page.`,
    topicDetails2_3: `Explore how to create powerful UX Success Metrics so that…`,
    topicDetails2_bullet1: `Your entire team and your stakeholders know exactly what goals they need to aim for.`,
    topicDetails2_bullet2: `You know exactly when to celebrate your success.`,
    topicDetails2_bullet3: `Your senior management clearly sees how your work has improved users’ lives.`,

    topicTitle2_date1: 'Wednesday, Jan 15, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Jan 15, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
  {
    weekNum: '2',

    topicTitle1: 'Achieve Key Results with Your UX Progress Metrics',
    topicDetails1_1: `High-value, audacious goals take time. We need to show progress. `,
    topicDetails1_2: `Progress happens incrementally and, all too often, invisibly. Identify OKR key results that show your team's work while simultaneously promoting the improvements and benefits your designs are accumulating. Build up your team’s value as your users receive better-designed experiences.`,
    topicDetails1_3: `Discover how to create insightful UX Progress Metrics so that…`,
    topicDetails1_bullet1: `Your development and product teams are eager to prioritize the UX.`,
    topicDetails1_bullet2: `You know exactly what to report in your OKRs.`,
    topicDetails1_bullet3: `Your stakeholders immediately connect the UX improvement to key `,

    topicTitle1_date1: 'Monday, Jan 20, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Jan 20, 4pm - 5pm pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Eliminate the Costs of Poor UX with Problem-Value Metrics',
    topicDetails2_1: `Grow executive support for high-value UX efforts. `,
    topicDetails2_2: `Poor UX causes problems for your users and customers. And it often comes back to haunt your organization’s bottom line through the costs of support, training, and lost sales. Now, you can accurately show how much these costs continue to accumulate.`,
    topicDetails2_3: `Explore how to create influential Problem-Value Metrics so that…`,
    topicDetails2_bullet1: `You motivate senior management to take UX seriously because better UX is a win for everyone.`,
    topicDetails2_bullet2: `You win over the finance department as your UX efforts shrink corporate costs.`,
    topicDetails2_bullet3: `You have vital stakeholders praising how better UX improved their department’s fiscal performance.`,

    topicTitle2_date1: 'Wednesday, Jan 22, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Jan 22, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },

  {
    weekNum: '3',

    topicTitle1: 'Raise UX’s Visibility with Outcome-driven UX Metrics',
    topicDetails1_1: `Make UX metrics a core component of your organization’s culture. `,
    topicDetails1_2: `Tie your UX outcomes directly to the product roadmap. Show how every new capability and feature is on the path to becoming the product everyone wants to own and the service everyone wants to use. Influence the vital strategic decisions that impact user experiences.`,
    topicDetails1_3: `Discover how to create strategic UX metrics so that…`,
    topicDetails1_bullet1: `You reduce the risk of delivering unwanted features and poorly thought-through capabilities.`,
    topicDetails1_bullet2: `You get called into the high-level discussions and meetings that define where your products and services are heading.`,
    topicDetails1_bullet3: `Your UX work becomes proactive, and your understanding of your users becomes invaluable.`,

    topicTitle1_date1: 'Monday, Jan 27, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Jan 27, 4pm - 5pm pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Identifying Your UX Outcomes',
    topicDetails2_1: `Bring all the essential players together to identify critical UX Outcomes`,
    topicDetails2_2: `Your Outcome-driven UX Metrics become far more powerful when you’ve gotten buy-in from the vital stakeholders and champions. You’ll discover how to conduct a special UX outcome workshop to pinpoint the improvements in the lives of your users and customers.`,
    topicDetails2_3: `Explore the best practices for crafting UX Outcomes so that`,
    topicDetails2_bullet1: `You orchestrate the buy-in of what great UX looks like.`,
    topicDetails2_bullet2: `You establish a solid “definition of done” that your Agile teams can use to know what to deliver.`,
    topicDetails2_bullet3: `You prevent team members and stakeholders from jumping to solutions before everyone understands the problems.`,

    topicTitle2_date1: 'Wednesday, Jan 29, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Jan 29, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },


  {
    weekNum: '4',

    topicTitle1: 'Expanding Your UX Research',
    topicDetails1_1: `Use UX metrics as a wedge to sell better UX research.`,
    topicDetails1_2: `You’ll likely need to up your UX research game to deliver high-powered UX metrics. You can only know what to improve if you know what’s currently in need of improvement. Demonstrate the power of strategic UX research.`,
    topicDetails1_3: `Uncover how thorough UX research improves success so that…`,
    topicDetails1_bullet1: `You increase your organization’s UX maturity by building expertise in your users and their needs.`,
    topicDetails1_bullet2: `You ensure every item in your product roadmap solves a necessary problem that customers would be happy to pay for.`,
    topicDetails1_bullet3: `You discover innovative ways to anticipate your customers’ needs and exceed your users’ expectations.`,

    topicTitle1_date1: 'Monday, Feb 3, 2pm - 3pm ET',
    topicTitle1_title1: 'Watch the Lecture*',
    topicTitle1_date2: 'Monday, Feb 3, 4pm - 5pm pm ET',
    topicTitle1_title2: 'Live Coaching with Jared',


    topicTitle2: 'Crafting Your UX Vision',
    topicDetails2_1: `Make your organization an innovative market leader`,
    topicDetails2_2: `A UX vision describes what your experiences will be like in the distant future. You’ll inspire your organization to deliver exceptional products and services for years by crafting a clear vision. You instantly merge future OKR objectives into a human-centered goal with broad support.`,
    topicDetails2_3: `Discover how a compelling UX vision drives your organization so that…`,
    topicDetails2_bullet1: `You influence the highest levels of your organization’s strategy.`,
    topicDetails2_bullet2: `You make your organization the envy of your entire industry, with every other organization holding you up as their goal.`,
    topicDetails2_bullet3: `You drive your organization to see UX as the central competitive promise for everything they do.`,

    topicTitle2_date1: 'Wednesday, Feb 5, 2pm - 3pm ET',
    topicTitle2_title1: 'Watch the Lecture*',
    topicTitle2_date2: 'Wednesday, Feb 5, 4pm - 5pm ET',
    topicTitle2_title2: 'Live Coaching with Jared',
  },
]